
import { Vue, Options } from "vue-class-component";
import copy from "copy-text-to-clipboard";
import { BalanceController } from "@/app/ui/controllers/BalanceController";
import { FlagsPermissionTopUpManual } from "@/feature-flags/flags-permission-topup-manual";
import { AccountController } from "@/app/ui/controllers/AccountController";
import BannerCashback from "./program-cashback-banner.vue";

@Options({
  computed: {
    isViewTransactionHistory() {
      return FlagsPermissionTopUpManual.permission_topup_manual_history_view_list.isEnabled();
    }
  },
  components: {
    BannerCashback
  }
})
export default class ModalTabTopupManual extends Vue {
  created() {
    BalanceController.getListBankManual();
  }

  // filter status
  statusSelect = false;
  get banks() {
    if (BalanceController.listBankManual?.data.length === 0) {
      return [
        {
          name: "Pilih Bank",
          value: 0
        }
      ];
    }
    return BalanceController.listBankManual?.data.map(e => {
      return {
        name: e.internalBankAccountName,
        value: e.internalBankAccountId
      };
    });
  }
  get detailSelectedBankManual() {
    return BalanceController.detailBankManual;
  }
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  get selectedBankManual() {
    return BalanceController.selectedBankManual;
  }
  onSelectMethodBank(value: { name: string; value: number }) {
    BalanceController.setSelectedBankManual(value);
    BalanceController.getDetailBankManual(value.value);
  }

  copyManual(value: string) {
    copy(value);
    if (copy(value)) {
      const x: any = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  }

  showTransactionHistory() {
    BalanceController.setShowTopupTransactionHistory(true);
    BalanceController.setOpenModalTopup(false);
  }

  onUpload() {
    BalanceController.setShowTopupUploadForm(true);
  }

  get activeCashback() {
    return AccountController.activeCashback;
  }

  get isAnyCashbackConfigActive() {
    return AccountController.accountData.isPosAccount && this.activeCashback.data.ccbId;
  }
}
